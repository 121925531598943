<template>
  <div  :class="$vuetify.breakpoint.smAndDown ? '' : 'width-45'">
    <v-card
      justify="center"
      width="100%"
      class="mx-auto px-10"
      :class="$vuetify.breakpoint.smAndDown ? 'pt-2 px-4' : 'pt-6 px-6'"
    >
      <v-row>
        <v-col>
          <v-img
            justify="center"
            class="m-auto"
            contain
            :src="$images.maropostLogo"
          />
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col class="text-center mt-2">
          <h3 class="font-weight-large">Create Profile</h3>
          <p class="body-2 font-regular login-grey--text">
            Your Email Address: {{ $route.query.email }}
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="pb-0"
          >
          <v-text-field
            ref="first_name"
            v-model="fields.first_name.value"
            :rules="[rules.required]"
            :error="fields.first_name.error"
            label="First Name"
            outlined
            :messages="fields.first_name.messages"
            color="mp-secondary"
            @keyup.enter="handleSubmit"
          />
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            ref="last_name"
            v-model="fields.last_name.value"
            :rules="[rules.required]"
            :error="fields.last_name.error"
            label="Last Name"
            outlined
            :messages="fields.last_name.messages"
            color="mp-secondary"
            @keyup.enter="handleSubmit"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-text-field
            ref="password"
            v-model="fields.password.value"
            :rules="[rules.required, rules.strongPassword]"
            :append-icon="
              fields.password.masked ? 'visibility' : 'visibility_off'
            "
            label="Password"
            :type="fields.password.masked ? 'text' : 'password'"
            outlined
            :error="fields.password.error"
            :messages="fields.password.messages"
            color="mp-secondary"
            @click:append="fields.password.masked = !fields.password.masked"
            @keyup.enter="handleSubmit"
          />
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            ref="password_confirmation"
            v-model="fields.password_confirmation.value"
            :rules="[rules.match]"
            :append-icon="
              fields.password_confirmation.masked
                ? 'visibility'
                : 'visibility_off'
            "
            label="Password Confirmation"
            :type="fields.password_confirmation.masked ? 'text' : 'password'"
            outlined
            :error="fields.password_confirmation.error"
            :messages="fields.password_confirmation.messages"
            color="mp-secondary"
            @click:append="
              fields.password_confirmation.masked =
                !fields.password_confirmation.masked
            "
            @keyup.enter="handleSubmit"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-if="errorMessage != ''" class="caption red--text">
            {{ errorMessage }}
          </div>
          <v-row
            class="caption text-left login-grey--text"
            v-for="(rule, index) in passwordRules"
            v-bind:key="index"
          >
            <v-col cols="auto" class="pr-0 py-0"
              ><v-icon class="body-1" :color="iconColor(rule)">{{
                rule.isValid !== false ? "mdi-check-circle" : "mdi-close-circle"
              }}</v-icon></v-col
            >
            <v-col class="py-0"
              ><span
                :style="
                  'font-weight:400;font-size:12px;color:' + textColor(rule)
                "
                >{{ rule.text }}</span
              ></v-col
            >
          </v-row>
          <div class="my-2">
            <v-btn
              block
              x-large
              v-track="'profile-submit'"
              class="mt-8 white--text font-weight-bold"
              style="width: auto"
              :loading="auth_processing"
              @click="handleSubmit"
              color="#5B616F"
            >
              Submit
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <snackbar
      @close="closeSnackbar"
      :snackbarValue="!!successMessage"
      :snackBarType="snackBarType"
    >
      {{ successMessage }}
    </snackbar>
    <div :class="$vuetify.breakpoint.smAndDown ? `mobile-footer-text footer-text` : 'footer-text'" class="mt-4">
      &copy; Maropost Inc  {{ this.getYear }} <span class="mx-3">|</span><a
            target="_blank"
            style="text-decoration:none;color:white;"
            href="https://www.maropost.com/privacy-policy/"
          >Privacy Policy</a>
    </div>
  </div>
</template>

<script>
import { addUser } from "@/services/users";
import Snackbar from "@/components/shared/Snackbar.vue";
import { mapGetters } from "vuex";
import { toMarketingCluster } from "@/utils";
export default {
  components: { Snackbar },
  data() {
    return {
      snackBarType: "",
      passwordRules: [
        { text: "Password must be at least 8 characters long", isValid: "" },
        {
          text: "Password must contain at least one upper case character",
          isValid: "",
        },
        {
          text: "Password must contain at least one special characters (-,+!)",
          isValid: "",
        },
        { text: "Password must contain at least one number", isValid: "" },
        {
          text: "Password cannot have same character consecutively",
          isValid: "",
        },
      ],
      errorMessage: "",
      successMessage: "",
      isLoading: true,
      rules: {
        required: (value) => {
          this.fields.first_name.error = false;
          this.fields.last_name.error = false;
          this.fields.password.error = false;
          this.fields.password_confirmation.error = false;
          this.fields.first_name.messages = "";
          this.fields.last_name.messages = "";
          this.fields.password.messages = "";
          this.fields.password_confirmation.messages = "";
          return !!value || "Required";
        },
        match: (value) =>
          value === this.fields.password.value || "Password do not Match.",
        strongPassword: (value) => {
          if (value.length == 0) {
            this.passwordRules[0].isValid = "";
            this.passwordRules[1].isValid = "";
            this.passwordRules[2].isValid = "";
            this.passwordRules[3].isValid = "";
            this.passwordRules[4].isValid = "";
            return false;
          }
          let flag = true;
          this.passwordRules[0].isValid = true;
          if (!/.{8,}/.test(value)) {
            this.passwordRules[0].isValid = false;
            flag = false;
          }

          this.passwordRules[1].isValid = true;
          if (!/[A-Z]+/.test(value)) {
            this.passwordRules[1].isValid = false;
            flag = false;
          }

          this.passwordRules[2].isValid = true;
          if (!/(?=.*?[#?!@$%^&*-])+/.test(value)) {
            this.passwordRules[2].isValid = false;
            flag = false;
          }

          this.passwordRules[3].isValid = true;
          if (!/[0-9]+/.test(value)) {
            this.passwordRules[3].isValid = false;
            flag = false;
          }

          this.passwordRules[4].isValid = true;
          if (/^\S*(.)\1\S*/.test(value)) {
            this.passwordRules[4].isValid = false;
            flag = false;
          }
          return flag;
        },
      },
      fields: {
        password: {
          value: "",
          error: false,
          messages: "",
          masked: false,
        },
        first_name: {
          value: "",
          error: false,
          messages: "",
        },
        last_name: {
          value: "",
          error: false,
          messages: "",
        },
        password_confirmation: {
          value: "",
          error: false,
          messages: "",
          masked: false,
        },
      },
      auth_processing: false,
    };
  },
  computed: {
    getYear()  {
    return new Date().getFullYear();
  },
  ...mapGetters({
    isLoggedIn: "auth/isLoggedIn",
    cookieToken: "auth/cookieToken",
    }),
  },
  methods: {
    /**
     * Closes the snackbar
     */
    closeSnackbar() {
      this.successMessage = "";
    },

    iconColor(rule) {
      return rule.isValid === ""
        ? "transparent"
        : rule.isValid
        ? "#1B5E20"
        : "#B00020";
    },
    textColor(rule) {
      return rule.isValid === ""
        ? "rgba(0,0,0,0.6)"
        : rule.isValid
        ? "rgba(0,0,0,0.87)"
        : "#B00020";
    },
    handleSubmit(e) {
      e.preventDefault();
      if (
        this.passwordRules[0].isValid &&
        this.passwordRules[1].isValid &&
        this.passwordRules[2].isValid &&
        this.passwordRules[3].isValid &&
        this.passwordRules[4].isValid &&
        this.$refs.first_name.validate() &&
        this.$refs.last_name.validate() &&
        this.$refs.password.validate() &&
        this.$refs.password_confirmation.validate()
      ) {
        this.loading = true;
        let payload = null;
        let data = {
          firstName: this.fields.first_name.value,
          lastName: this.fields.last_name.value,
          password: this.fields.password.value,
          email: this.$route.query.email,
          cluster: this.$route.query.cluster,
        };
        payload = data;
        let token = this.$route.query.token;

        const config = {
          skipAppendAuthorizationToken: true,
          headers: { Authorization: token },
        };

        addUser(payload, config)
          .then(() => {
            this.successMessage = `User added successfully`;
            this.snackBarType = "success";
            this.loading = false;
            if(!this.isLoggedIn) {
            this.$router.push({ path: "/login" });
            } else{
              toMarketingCluster(this.cookieToken);
            }
          })
          .catch((error) => {
            this.successMessage = error.response.data.errors;
            this.snackBarType = "error";
            this.loading = false;
          });
      } else {
        if (this.fields.first_name.value === "") {
          this.fields.first_name.error = true;
          this.fields.first_name.messages = "Required.";
        }
        if (this.fields.last_name.value === "") {
          this.fields.last_name.error = true;
          this.fields.last_name.messages = "Required.";
        }
        if (this.fields.password.value === "") {
          this.passwordRules[0].isValid = false;
          this.passwordRules[1].isValid = false;
          this.passwordRules[2].isValid = false;
          this.passwordRules[3].isValid = false;
          this.passwordRules[4].isValid = false;
        }
      }
    },
  },
};
</script>
<style>
.login-grey--text {
  color: rgba(0, 0, 0, 0.6);
}
.width-45{
  width: 45%;
}
</style>
